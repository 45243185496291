export default [
  ["", "", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", "", ""],
  [
    "",
    "",
    "",
    "",
    "",
    { correct: "0.30", dx: 25 },
    "",
    "",
    { correct: "16012.50", dx: 35 },
  ],
  ["", "", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", "", ""],
  [
    "",
    "",
    "",
    "",
    "",
    { correct: "", dx: 25 },
    { correct: "0.12", dx: 25 },
    "",
    { correct: "436374.40", dx: 42 },
  ],
  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    { correct: "", dx: 25 },
    { correct: "309150.00", dx: 42 },
  ],
  ["", "", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", "", ""],
]
