import React, { forwardRef } from "react"

const Factory = forwardRef((props, ref) => {
  return (
    <g
      id="factory"
      transform-origin="5.29px 5.29px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      opacity="0"
    >
      <path
        id="path75"
        d="M 12.60252,-3.9074463e-7 H 10.061128 L 9.39967,13.229171 h 3.829496 z"
        fill="#bf360c"
        strokeWidth="0.34813592"
      />
      <path
        id="path77"
        d="M 9.7478055,6.2664466 H 8.355262 V 4.1776316 L 4.177631,6.2664466 V 4.1776316 L 0,6.2664466 v 6.9627244 h 9.7478055 z"
        fill="#e64a19"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect79"
        height="1.3925437"
        width="1.3925437"
        y="7.6590004"
        x="1.3925455"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect81"
        height="1.3925437"
        width="1.3925437"
        y="7.6590004"
        x="4.1776295"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect83"
        height="1.3925437"
        width="1.3925437"
        y="7.6590004"
        x="6.9627171"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect85"
        height="1.3925437"
        width="1.3925437"
        y="10.444069"
        x="1.3925455"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect87"
        height="1.3925437"
        width="1.3925437"
        y="10.444069"
        x="4.1776295"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
      <rect
        id="rect89"
        height="1.3925437"
        width="1.3925437"
        y="10.444069"
        x="6.9627171"
        fill="#ffc107"
        strokeWidth="0.34813592"
      />
    </g>
  )
})

export default Factory
