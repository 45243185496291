import React, { forwardRef } from "react"

const Brackets = forwardRef((props, ref) => {
  return (
    <g
      ref={ref}
      id="table"
      fill="none"
      stroke="black"
      strokeWidth="0.4"
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
    >
      <rect
        fill="var(--mainGray)"
        id="rect4791"
        width="9.7533913"
        height="9.8212175"
        x="0.2260312"
        y="0.22603141"
        ry="0.83671325"
      />
      <path d="M 0.21177782,2.2778088 H 9.9366536" id="path4793" />
      <path d="M 3.4771666,10.006211 V 2.3188498" id="path4793-5" />
      <path d="M 6.7282986,10.006219 V 2.3188588" id="path4793-5-7" />
      <path d="M 7.8119066,4.7566468 H 8.8956177" id="path4831" />
      <path d="m 4.5607706,4.7566468 h 1.08371" id="path4831-9" />
      <path d="m 1.3096426,4.7566468 h 1.08371" id="path4831-6" />
      <path d="M 7.811906,6.0849328 H 8.8956161" id="path4831-0" />
      <path d="M 4.5607695,6.0849328 H 5.6444796" id="path4831-9-8" />
      <path d="M 1.3096409,6.0849328 H 2.393351" id="path4831-6-6" />
      <path d="M 7.811906,7.4132128 H 8.8956161" id="path4831-4" />
      <path d="M 4.5607695,7.4132128 H 5.6444796" id="path4831-9-7" />
      <path d="M 1.3096409,7.4132128 H 2.393351" id="path4831-6-9" />
      <path d="M 7.811906,8.7414948 H 8.8956161" id="path4831-92" />
      <path d="M 4.5607695,8.7414948 H 5.6444796" id="path4831-9-83" />
      <path d="M 1.3096409,8.7414948 H 2.393351" id="path4831-6-0" />
      <path d="M 7.811906,3.4283668 H 8.8956161" id="path4831-7" />
      <path d="M 4.5607695,3.4283668 H 5.6444796" id="path4831-9-89" />
      <path d="M 1.3096409,3.4283668 H 2.393351" id="path4831-6-1" />
    </g>
  )
})

export default Brackets
