import React, { PureComponent } from "react"
import styles from "../../css/home/Features.module.css"
import features from "../../constants/features"

const queries = ["(min-width: 1000px)", "(min-width: 650px)"]
const values = [3, 2]
const defaultNcols = 1 // this value is used when no media queries is true (small screen)

class Features extends PureComponent {
  constructor(props) {
    super(props)
    this.mediaQueryLists = []
    this.state = {
      ncols: 1,
    }
  }

  componentDidMount() {
    if (window && window.matchMedia) {
      this.mediaQueryLists = queries.map(q => {
        return window && window.matchMedia ? window.matchMedia(q) : false
      })
      this.mediaQueryLists.forEach(mql => mql.addListener(this.setNcols))
      this.setNcols()
    }
  }

  componentWillUnmount() {
    this.mediaQueryLists.forEach(mql => {
      if (mql) {
        mql.removeListener(this.setNcols)
      }
    })
  }

  getNcols() {
    // Get index of first media query that matches
    const index = this.mediaQueryLists.findIndex(mql => {
      return mql && mql.matches
    })
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultNcols
  }

  setNcols = () => {
    this.setState({
      ncols: this.getNcols(),
    })
  }

  arrangeItems() {
    const colsize = Math.ceil(features.length / this.state.ncols)
    return Array(this.state.ncols)
      .fill(0)
      .map((item, index) => {
        return features.slice(
          index * colsize,
          Math.min(features.length, (index + 1) * colsize)
        )
      })
  }

  render() {
    return (
      <section className="default-section">
        <div className="default-center">
          <h1>Features</h1>
          <div className={styles.grid}>
            {this.arrangeItems().map((col, colIndex) => {
              return (
                <div key={colIndex} className={styles.gridColumn}>
                  {col.map((item, index) => {
                    return <li key={index}>{item}</li>
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default Features
