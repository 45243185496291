import React, { forwardRef } from "react"

const Bank = forwardRef((props, ref) => {
  return (
    <g
      id="bank"
      transform-origin="5.29px 5.29px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      opacity="0"
    >
      <path
        d="m 12.718341,5.7704407 c 0.49374,0 0.69986,-0.66994 0.29881,-0.97875 l -6.1022104,-4.68791 a 0.50827715,0.53679694 0 0 0 -0.60079,0 l -6.10220999,4.68791 c -0.40105,0.30714 -0.19493,0.97875 0.3004,0.97875 H 1.5014206 V 12.081741 H 0.35096061 c -0.0703,0 -0.1278,0.0607 -0.1278,0.13496 v 0.87751 c 0,0.0741 0.0576,0.13496 0.1278,0.13496 H 12.878131 c 0.0703,0 0.1278,-0.0607 0.1278,-0.13496 v -0.87751 c 0,-0.0742 -0.0575,-0.13496 -0.1278,-0.13496 h -1.15046 V 5.7704407 Z M 4.5213606,12.081741 h -1.86949 V 5.7704407 h 1.86949 z m 3.01994,0 h -1.86949 V 5.7704407 h 1.86949 z m 3.0359204,0 H 8.6917506 V 5.7704407 h 1.8854704 z"
        id="bank-path"
        fill="#00aad4"
        strokeWidth="0.01642071"
      />
    </g>
  )
})

export default Bank
