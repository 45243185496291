import React from "react"
import Layout from "../components/Layout"
import AnimatedHero from "../components/home/AnimatedHero"
import QA from "../components/home/QA"
import Features from "../components/home/Features"
import Flashy from "../components/home/Flashy"

export default () => (
  <Layout
    title="Autonomous Data Quality for enterprise data."
    description="Automatic Enterprise Data Quality solution powered by AI. Fully autonomous, self-learning and self-adjusting system that can form a closed-loop Data Quality Assurance pipeline. It can discover and fix most issues in your data before they cause incidents."
    contentType="website"
  >
    <AnimatedHero></AnimatedHero>
    <Flashy />
    <Features />
    <QA />
  </Layout>
)
