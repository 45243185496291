import React, { forwardRef } from "react"

const TapHereText = forwardRef((props, ref) => {
  const scale = props.scale ? props.scale : 1
  const x = props.x ? props.x : 0
  const y = props.y ? props.y : 0
  const rx = props.rx ? props.rx : 20
  const ry = props.ry ? props.ry : 20
  const width = props.width ? props.width : 100
  const height = props.height ? props.height : 100
  return (
    <g
      ref={ref}
      id="frame"
      fill={props.text ? "white" : "var(--mainGray)"}
      stroke="black"
      transform={`matrix(${scale},0,0,${scale},${x},${y})`}
      //   transformOrigin="400 100"
      className="svg-comp"
    >
      <rect
        width={width / 2}
        height={height / 3}
        rx={rx}
        ry={ry}
        x={width / 2}
        y={height / 2}
        transform={`translate(-${width / 4}, -${height / 6})`}
      />
      <text
        x={width / 2}
        y={height / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="lighter"
        stroke="None"
        fill="black"
      >
        {props.text}
      </text>
    </g>
  )
})

export default TapHereText
