import React, { Component } from "react"
import { TimelineLite, Power2 } from "gsap"

class Server extends Component {
  led1 = React.createRef()
  led2 = React.createRef()
  led3 = React.createRef()
  tl = new TimelineLite()

  R(max, min) {
    return Math.random() * (max - min) + min
  }

  componentDidMount() {
    this.tl.from(this.led1.current, this.R(0.05, 0.3), {
      opacity: 0,
      ease: Power2.easeInOut,
      repeat: -1,
      yoyo: true,
    })
    this.tl.from(this.led2.current, this.R(0.05, 0.3), {
      opacity: 0,
      ease: Power2.easeInOut,
      repeat: -1,
      yoyo: true,
    })
    this.tl.from(this.led3.current, this.R(0.05, 0.3), {
      opacity: 0,
      ease: Power2.easeInOut,
      repeat: -1,
      yoyo: true,
    })
  }

  render() {
    return (
      <g
        id="server"
        transform-origin="5.29px 5.29px"
        ref={this.props.serverRef}
        transform={`matrix(${this.props.scale},0,0,${this.props.scale},${this.props.x},${this.props.y})`}
        className="svg-comp"
      >
        <rect
          id="server2"
          width="13.229167"
          height="3.175"
          x="0"
          y="5.0270791"
          fill="#008080"
          ry="0.5291667"
        />
        <rect
          id="led2"
          width="1.0583334"
          height="1.0583334"
          x="11.112495"
          y="6.0854135"
          fill="#00ff00"
          ref={this.led2}
        />
        <rect
          id="server3"
          width="13.229167"
          height="3.175"
          x="0"
          y="9.260417"
          fill="#008080"
          ry="0.5291667"
        />
        <rect
          id="led3"
          width="1.0583334"
          height="1.0583334"
          x="11.112495"
          y="10.318751"
          fill="#00ff00"
          ref={this.led3}
        />
        <rect
          id="server1"
          width="13.229167"
          height="3.175"
          x="0"
          y="0.79375666"
          fill="#008080"
          ry="0.5291667"
        />
        <rect
          id="led1"
          width="1.0583334"
          height="1.0583334"
          x="11.112495"
          y="1.8520913"
          fill="#00ff00"
          ref={this.led1}
        />
      </g>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Server serverRef={ref} {...props} />
))
