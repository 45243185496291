export default function (x0, cp0, cp1, x1, t) {
  // implementation of de Casteljau's algorithm from here https://stackoverflow.com/a/14205183
  const ax = x0[0] + (cp0[0] - x0[0]) * t
  const ay = x0[1] + (cp0[1] - x0[1]) * t
  const bx = cp0[0] + (cp1[0] - cp0[0]) * t
  const by = cp0[1] + (cp1[1] - cp0[1]) * t
  const cx = cp1[0] + (x1[0] - cp1[0]) * t
  const cy = cp1[1] + (x1[1] - cp1[1]) * t

  const dx = ax + (bx - ax) * t
  const dy = ay + (by - ay) * t
  const ex = bx + (cx - bx) * t
  const ey = by + (cy - by) * t

  const x = dx + (ex - dx) * t
  const y = dy + (ey - dy) * t

  return { x: x, y: y }
}
