import React, { forwardRef } from "react"

const Agent = forwardRef((props, ref) => {
  return (
    <g
      id="agent"
      transform-origin="5.29px 5.29px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      opacity="0"
    >
      <path
        d="m 11.42519,5.4115541 v 2.40541 h 0.601324 v -2.40541 z m -10.222537,0 v 2.40541 h 0.601325 v -2.40541 z m 10.185254,-1.20271 h 0.638607 a 1.2026513,1.2027095 0 0 1 1.202653,1.20271 v 2.40541 a 1.2026513,1.2027095 0 0 1 -1.202653,1.20273 H 11.387907 A 4.8112065,4.8114392 0 0 1 6.614583,13.229164 v -1.20272 a 3.607954,3.6081286 0 0 0 3.607954,-3.6081199 v -3.60814 a 3.6079545,3.6081291 0 1 0 -7.215908,0 v 4.20951 H 1.202653 A 1.2026513,1.2027095 0 0 1 0,7.8169641 v -2.40541 a 1.2026513,1.2027095 0 0 1 1.202653,-1.20271 h 0.638606 a 4.8112065,4.8114392 0 0 1 9.546648,0 z"
        fill="#55d400"
        strokeWidth="0.60134017"
        id="path1544"
      />
      <path
        d="m 6.590498,2.9420341 c -1.060589,0 -1.894516,0.38038 -2.383701,1.03875 -0.489185,0.65836 -0.630326,1.49957 -0.630326,2.40158 0,1.02621 0.415743,1.97444 0.972121,2.69011 0.556383,0.71568 1.232048,1.2607099 2.041906,1.2607099 0.809855,0 1.485522,-0.5450299 2.041901,-1.2607099 0.556382,-0.71567 0.976564,-1.6639 0.976564,-2.69011 0,-0.90201 -0.141129,-1.74322 -0.630328,-2.40158 -0.489185,-0.65837 -1.327552,-1.03875 -2.388137,-1.03875 z m 0,1.14084 c 0.82047,0 1.208791,0.21611 1.473719,0.57265 0.264932,0.35657 0.408381,0.9532 0.408381,1.72684 0,0.70539 -0.314584,1.44553 -0.736859,1.98871 -0.422278,0.54321 -0.972206,0.82568 -1.145241,0.82568 -0.17304,0 -0.718526,-0.28247 -1.140803,-0.82568 -0.422278,-0.54318 -0.736861,-1.28332 -0.736861,-1.98871 0,-0.77364 0.138997,-1.37027 0.403941,-1.72684 0.264932,-0.35654 0.65325,-0.57265 1.473723,-0.57265 z"
        fill="#55d400"
        strokeWidth="0.60134017"
        id="path1557-1"
      />
    </g>
  )
})

export default Agent
