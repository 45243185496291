import React, { forwardRef } from "react"

const Brackets = forwardRef((props, ref) => {
  return (
    <g
      ref={ref}
      id="callouts"
      fill="none"
      stroke="black"
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
    >
      <path
        strokeWidth="0.26499999"
        d="M 0.30905757,39.234672 18.280251,64.018125 c 0.526102,0.526103 1.253771,0.850639 2.060016,0.850639 h 42.967449 c 1.612509,0 2.910674,-1.298164 2.910674,-2.910674 V 32.555812"
        id="table-callout-1"
        className="svg-comp"
      />
      <path
        strokeWidth="0.26499999"
        d="M 0.24490557,25.901935 18.280251,1.0935 c 0.314107,-0.43187196 1.253771,-0.85063896 2.060016,-0.85063896 h 42.967449 c 1.612509,0 2.910674,1.29816396 2.910674,2.91067396 v 29.402277"
        id="table-callout-2"
        className="svg-comp"
      />
    </g>
  )
})

export default Brackets
