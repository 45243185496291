export default [
  ["", "", "", "", "", "Retail", "Wholesale", "Local", "Invoice"],
  [
    "Item",
    "Customer",
    "Country",
    "Qty",
    "RRP",
    "margin",
    "margin",
    "tax",
    "net",
  ],
  [
    "Charcoal floor tile",
    "Tiles paradise",
    "UK",
    "1000",
    "10.25",
    "0.30",
    "",
    "",
    "7175",
  ],
  [
    "Charcoal wall tile",
    "Tiles paradise",
    "UK",
    "2500",
    "9.15",
    "0.32",
    "",
    "",
    "15555",
  ],
  [
    "Charcoal border tile",
    "Tiles paradise",
    "UK",
    "600",
    "6.75",
    "0.30",
    "",
    "",
    "2835",
  ],
  [
    "Charcoal corner tile",
    "Tiles paradise",
    "UK",
    "900",
    "5.55",
    "0.30",
    "",
    "",
    "3496.5",
  ],
  [
    "Oak floor tile",
    "Tiles wholesale",
    "US",
    "40000",
    "12.65",
    "0.20",
    "",
    "0.02",
    "396704",
  ],
  [
    "Sandstone floor tile",
    "National tiles",
    "UK",
    "30000",
    "11.45",
    "",
    "0.10",
    "0.02",
    "302967",
  ],
  [
    "Charcoal floor tile",
    "Top tiles",
    "UK",
    "800",
    "10.25",
    "0.35",
    "",
    "",
    "5330",
  ],
  [
    "Sandstone floor tile",
    "Tile giant",
    "UK",
    "1200",
    "11.45",
    "0.35",
    "",
    "",
    "8931",
  ],
]
