import React from "react"
import Link from "../components/Link"

export default [
  "Did you want to discover not-yet-known errors in your data?",
  "Did you want to discover issues before they cause incidents?",
  "Has your list of business rules to control data quality become too large?",
  "Your business analysts can't keep business rules up-to-date?",
  <>
    <Link to="/contact">Tell us</Link>
    <span> your story</span>
  </>,
]
