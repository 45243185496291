import React, { forwardRef, Fragment } from "react"

const TextCorrection = forwardRef((props, ref) => {
  const scale = props.scale ? props.scale : 1
  const x = props.x ? props.x : 0
  const y = props.y ? props.y : 0

  function drawActualValue(cell, key) {
    if (cell.actual && !("correct" in cell)) {
      return (
        <text
          x={cell.x}
          y={cell.y}
          key={`actual${key}`}
          textAnchor={cell.textAnchor}
        >
          {cell.actual}
        </text>
      )
    }
    return null
  }

  function drawCorrectValue(cell, key) {
    const shift = cell.actual ? -10 : 0
    if (cell.correct) {
      return (
        <text
          x={cell.x + shift}
          y={cell.y + shift}
          key={`correct${key}`}
          textAnchor={cell.textAnchor}
        >
          {cell.correct}
        </text>
      )
    }
    return null
  }

  function crossActualValue(cell, key) {
    if ("correct" in cell && cell.actual) {
      // draw strike through line using props.strike.dx
      const dx = cell.dx
      const dy = cell.dy || -10
      const x0 = cell.x - (cell.textAnchor === "end" ? dx : 0)
      const y0 = cell.y
      return (
        <line
          key={`cross${key}`}
          x1={x0}
          y1={y0}
          x2={x0 + dx}
          y2={y0 + dy}
          className={cell.crossStyle}
        />
      )
    }
    return null
  }

  return (
    <g
      ref={ref}
      id="row"
      stroke="none"
      transform={`matrix(${scale},0,0,${scale},${x},${y})`}
      // className={props.style}
      className={`${props.style} svg-comp`}
    >
      {props.cells.map((cell, index) => {
        return (
          <Fragment key={index}>
            {drawActualValue(cell, index)}
            {crossActualValue(cell, index)}
            {drawCorrectValue(cell, index)}
          </Fragment>
        )
      })}
    </g>
  )
})

export default TextCorrection
