import Brackets from "./Brackets"
import Table from "./Table"

const scale = 3.78072
const [offsetX, offsetY] = [430 - 150, 82]

const tableTemplate = [
  // appear first
  { x: 135, y: 105 },
  // pause, next 4 appear staggered
  { x: 90, y: 40 },
  { x: 180, y: 40 },
  { x: 90, y: 170 },
  { x: 180, y: 170 },
  // pause, next 2 staggered
  { x: 70, y: 105 },
  { x: 200, y: 105 },
  // pause, next 2 staggered
  { x: 125, y: 45 },
  { x: 145, y: 165 },
  // pause, next 4 staggered
  { x: 190, y: 80 },
  { x: 185, y: 145 },
  { x: 85, y: 130 },
  { x: 95, y: 70 },
]

function templateTables() {
  return tableTemplate.map((item, index) => {
    return {
      id: `table${index}`,
      type: "templateTable",
      component: Table,
      props: {
        scale: scale,
        x: item.x + offsetX,
        y: item.y + offsetY,
      },
    }
  })
}

function randomTables(n) {
  return Array(n)
    .fill(0)
    .map((item, index) => {
      return {
        id: `table${index + tableTemplate.length}`,
        type: "randomTable",
        component: Table,
        props: {
          scale: scale,
          ...randomXY(),
        },
      }
    })
}

function randomXY() {
  const [minX, maxX] = [70, 200]
  const [minY, maxY] = [40, 170]

  return {
    x: Math.random() * (maxX - minX) + minX + offsetX,
    y: Math.random() * (maxY - minY) + minY + offsetY,
  }
}

export default [
  {
    id: "brackets",
    type: "brackets",
    component: Brackets,
    props: { scale: scale, x: offsetX, y: offsetY },
  },
  ...templateTables(),
  ...randomTables(15),
]
