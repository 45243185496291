// import React from "react"

export default [
  "Designed for ERP data",
  "Detects both known and unknown issues",
  "Suggests correct values",
  "No documentation needed",
  "Self learning",
  "Self adjusting to changes in data",
]
