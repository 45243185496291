import Factory from "./Factory"
import Agent from "./Agent"
import Handshake from "./Handshake"
import Bi from "./Bi"
import Bank from "./Bank"
import Cart from "./Cart"
import Server from "./Server"
import Wires from "./Wires"

// Scene component is not needed as a component because I cant do animation of a parents viewbox inside a child. All anumation will have to be done in parent component. In this file I will only define list of objects in the scene that parent component will render

const scale = 3.78072

export default [
  {
    id: "factory",
    type: "factory",
    component: Factory,
    props: { scale: scale, x: 235, y: 275 },
  },
  {
    id: "agent",
    type: "other icons",
    component: Agent,
    props: { scale: scale, x: 235, y: 85 },
  },
  {
    id: "handshake",
    type: "other icons",
    component: Handshake,
    props: { scale: scale, x: 380, y: 35 },
  },
  {
    id: "bi",
    type: "other icons",
    component: Bi,
    props: { scale: scale, x: 525, y: 85 },
  },
  {
    id: "bank",
    type: "other icons",
    component: Bank,
    props: { scale: scale, x: 525, y: 275 },
  },
  {
    id: "cart",
    type: "other icons",
    component: Cart,
    props: { scale: scale, x: 380, y: 325 },
  },
  {
    id: "server",
    type: "server",
    component: Server,
    props: { scale: scale, x: 380, y: 180 },
  },
  {
    id: "wires",
    type: "wires",
    component: Wires,
    props: { scale: scale, x: 366, y: 174 },
  },
]
