import React, { forwardRef } from "react"

const Cart = forwardRef((props, ref) => {
  return (
    <g
      id="cart"
      transform-origin="5.29px 5.29px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      opacity="0"
    >
      <path
        d="m 12.129482,7.785542 1.08573,-5.374347 c 0.0785,-0.38804 -0.18378,-0.757551 -0.5375,-0.757551 H 3.656577 L 3.446049,0.49583598 C 3.393659,0.20724298 3.167879,-1.5441884e-8 2.906021,-1.5441884e-8 H 0.551206 C 0.246777,-1.5441884e-8 0,0.27762398 0,0.62012198 V 1.033522 c 0,0.342484 0.246777,0.620122 0.551206,0.620122 h 1.605027 l 1.613401,8.873742 c -0.385987,0.249735 -0.646086,0.717978 -0.646086,1.254846 0,0.799116 0.575839,1.446935 1.286165,1.446935 0.710339,0 1.286169,-0.647819 1.286169,-1.446935 0,-0.404991 -0.14806,-0.770885 -0.3864,-1.033537 h 4.81503 c -0.23831,0.262652 -0.38638,0.628546 -0.38638,1.033537 0,0.799116 0.57583,1.446935 1.28616,1.446935 0.71034,0 1.28616,-0.647819 1.28616,-1.446935 0,-0.572886 -0.296,-1.067948 -0.72527,-1.302382 l 0.12673,-0.627249 c 0.0785,-0.388041 -0.18379,-0.757552 -0.53751,-0.757552 H 5.009576 L 4.859253,8.268236 h 6.732759 c 0.25736,0 0.48047,-0.200352 0.5375,-0.482694 z"
        id="cart-path"
        fill="#aad400"
        strokeWidth="0.02436049"
      />
    </g>
  )
})

export default Cart
