import React, { Component } from "react"
import phrases from "../../constants/flashy"
import styles from "../../css/home/Flashy.module.css"

export default class Flashy extends Component {
  timeout = 8000
  interval = null

  state = {
    activePhraseId: null,
  }

  componentDidMount = () => {
    this.nextPhrase()
    this.interval = setInterval(this.nextPhrase, this.timeout)
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  nextPhrase = () => {
    if (Array.isArray(phrases) && phrases.length > 0) {
      // Init. Check props
      if (this.state.activePhraseId === null) {
        this.setState({
          activePhraseId: 0,
        })
      } else {
        const nextId =
          this.state.activePhraseId + 1 < phrases.length
            ? this.state.activePhraseId + 1
            : 0
        this.setState({
          activePhraseId: nextId,
        })
      }
    }
  }

  render() {
    return (
      <section className="default-section">
        {/* <div className="default-center"> */}
        <div className={styles.wrapper}>
          {phrases.map((item, index) => {
            return (
              <h3
                key={index}
                className={
                  index === this.state.activePhraseId
                    ? `${styles.flashy} ${styles.current}`
                    : styles.flashy
                }
              >
                {item}
              </h3>
            )
          })}
        </div>
        {/* </div> */}
      </section>
    )
  }
}
