import React, { forwardRef } from "react"

const Brackets = forwardRef((props, ref) => {
  const scale = props.scale ? props.scale : 1
  const x = props.x ? props.x : 0
  const y = props.y ? props.y : 0
  const rx = props.rx ? props.rx : 20
  const ry = props.ry ? props.ry : 20
  const width = props.width ? props.width : 100
  const height = props.height ? props.height : 100
  return (
    <g
      ref={ref}
      id={props.id}
      fill={props.cover ? "white" : "var(--mainGray)"}
      stroke="black"
      transform={`matrix(${scale},0,0,${scale},${x},${y})`}
      className="svg-comp"
      // visibility="hidden"
    >
      <rect
        width={width}
        height={height}
        rx={rx}
        ry={ry}
        // fill={props.cover ? null : "var(--mainGray)"}
        stroke="black"
        opacity={props.cover ? 0.7 : 1}
      />
    </g>
  )
})

export default Brackets
