import React, { Component, Fragment } from "react"
import styles from "../../css/home/AnimatedHero.module.css"
import { gsap, TimelineLite, TweenLite, Power2, Elastic } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

import Scene1 from "./animationComponents/scene1/Scene1"
import Scene2 from "./animationComponents/scene2/Scene2"
import { Scene3Actual, Scene3Zoom } from "./animationComponents/scene3/Scene3"
import Scene3Lens from "./animationComponents/scene3/MagGlass"
import solveBezier from "./animationComponents/scene3/BezierSolver"
import LensPath from "./animationComponents/scene3/LensPath"

gsap.registerPlugin(DrawSVGPlugin)

export default class AnimatedHero extends Component {
  constructor(props) {
    super(props)
    this.scene1Refs = {}
    this.scene2Refs = {}
    this.scene3Refs = {}
    this.scene3ZoomRefs = {}
    this.scene3MagRefs = []
    this.finishedShadeRef = null
    this.tapHereRef = null
    this.zoomScale = 1.5
    this.zoomed = React.createRef()
    this.viewport = React.createRef()
    this.clippath = React.createRef()
    this.lens = React.createRef()
    this.tl = new TimelineLite()
    this.magTl = new TimelineLite({ paused: true })
    // below TL only used once to reveal shade cover and the text box
    // at the end of animation
    this.coverTl = new TimelineLite({ paused: true })
    this.timer = TweenLite.delayedCall(10, () => this.magTl.reverse()).pause()
  }

  componentDidMount() {
    // this.animateScene1()
    // this.animateScene2()
    this.animateScene3()
  }

  componentWillUnmount() {
    // remove listeners
    this.viewport.current.removeEventListener("mouseleave", this.leaveAction)
    this.viewport.current.removeEventListener("mousemove", this.moveAction)
    this.tl.kill()
    this.tl.clear()
    this.magTl.kill()
    this.magTl.clear()
    this.coverTl.kill()
    this.coverTl.clear()
  }

  animateScene1() {
    //first get properly ordered
    const refObjArray = this.getRefsByType(this.scene1Refs, "other icons")
    const refWireArray = this.getRefById(
      this.scene1Refs,
      "wires"
    ).querySelectorAll("path")
    const wires = this.getRefById(this.scene1Refs, "wires")
    const factory = this.getRefById(this.scene1Refs, "factory")
    const server = this.getRefById(this.scene1Refs, "server")

    const popFrom = {
      scale: 0,
      stagger: 0.1,
      ease: Elastic.easeOut.config(2, 0.3),
    }

    this.tl
      .addLabel("scene1-start")
      .set(this.viewport.current, { attr: { viewBox: "60 200 400 200" } })
      .set(factory, { autoAlpha: 1 }, 1)
      .from(
        factory,
        1,
        {
          scale: 0,
          ease: Elastic.easeOut.config(2, 0.3),
        },
        "scene1-start"
      )
      .to(factory, 0.5, {
        scale: 4,
        ease: Elastic.easeOut.config([1.75, 1]),
        repeat: 10,
        yoyo: true,
      })
      .to(
        this.viewport.current,
        1,
        {
          attr: { viewBox: "0 0 800 400" },
          ease: Power2.easeInOut,
          delay: 2,
        },
        "scene1-start+=3"
      )
      .addLabel("factory-pan-out")
      .set(refObjArray, { autoAlpha: 1, stagger: 0.1 }, "factory-pan-out")
      .from(refObjArray, 0.5, popFrom, "factory-pan-out")
      // add line animation here
      .set(wires, { autoAlpha: 1 })
      .set(refWireArray, { autoAlpha: 1 })
      .from(refWireArray, 1, { drawSVG: 0 })
      .set(server, { autoAlpha: 1 })
      .from(server, 0.5, popFrom)
      .to([factory, ...refObjArray, wires], 1, { autoAlpha: 0 }, "+=3")
  }

  animateScene2() {
    const templateTableRefs = this.getRefsByType(
      this.scene2Refs,
      "templateTable"
    )
    const randomTableRefs = this.getRefsByType(this.scene2Refs, "randomTable")
    const brackets = this.getRefById(
      this.scene2Refs,
      "brackets"
    ).querySelectorAll("path")

    const server = this.getRefById(this.scene1Refs, "server")

    const popFrom = {
      scale: 0,
      stagger: 0.1,
      ease: Elastic.easeOut.config(2, 0.3),
    }

    this.tl
      .to(server, 1, {
        x: "-=150",
        ease: Power2.easeInOut,
        delay: 2,
      })
      .set(brackets, { autoAlpha: 1 })
      .from(brackets, 1, { drawSVG: 0 })
      .set(templateTableRefs[0], { autoAlpha: 1 })
      .from(templateTableRefs[0], 0.5, popFrom)
      .set(templateTableRefs.slice(1, 7), { autoAlpha: 1 }, "+=1")
      .from(templateTableRefs.slice(1, 7), 0.5, popFrom)
      .set(templateTableRefs.slice(7, 8), { autoAlpha: 1 }, "+=1")
      .from(templateTableRefs.slice(7, 8), 0.5, popFrom)
      .set(templateTableRefs.slice(8, 13), { autoAlpha: 1 }, "+=1")
      .from(templateTableRefs.slice(8, 13), 0.5, popFrom)
      .set(randomTableRefs, { autoAlpha: 1 }, "+=1")
      .from(randomTableRefs, 0.5, popFrom)
      .addLabel("scene2-ended")
      .to(
        [server, ...brackets, ...templateTableRefs, ...randomTableRefs],
        0.4,
        { x: "-=600", ease: Power2.easeOut },
        "+=1"
      )
      // hide from DOM to save cpu
      .set([server, ...brackets, ...templateTableRefs, ...randomTableRefs], {
        autoAlpha: 0,
      })
  }

  animateScene3() {
    const frameRef = this.getRefsById(this.scene3Refs, "frame")
    const actualRef = this.getRefsByType(this.scene3Refs, "actual")
    const zoomRef = [
      ...this.getRefsByType(this.scene3ZoomRefs, "zoom"),
      this.getRefById(this.scene3ZoomRefs, "frame"),
    ]

    this.scene3MagRefs = [this.lens.current, this.clippath.current]
    this.finishedShadeRef = this.getRefsById(this.scene3Refs, "coverFrame")
    this.tapHereRef = this.getRefsById(this.scene3Refs, "tapHere")

    var parent = this

    var followPathTl = new TimelineLite().to(frameRef, 3, {
      onUpdate: function () {
        parent.moveMagSvg(solveBezier(...LensPath, followPathTl.progress()))
      },
      onComplete: function () {
        parent.finishPlayback(parent)
      },
    })

    this.magTl
      // first hide text and shading
      // .fromTo(
      //   this.tapHereRef,
      //   { opacity: 1, scale: 1 },
      //   { opacity: 0, scale: 0, duration: 0.5 },
      //   0
      // )
      // .fromTo(
      //   this.finishedShadeRef,
      //   { opacity: 1 },
      //   { opacity: 0, duration: 0.5 },
      //   0
      // )
      // and then reveal lens
      .to(this.scene3MagRefs, 0.5, { opacity: 1, scale: 1 }, 0)
    // .fromTo(
    //   this.scene3MagRefs,
    //   { autoAlpha: 0, scale: 0 },
    //   { autoAlpha: 1, scale: 1, duration: 0.5 },
    //   0
    // )

    // this.coverTl
    //   .fromTo(
    //     this.finishedShadeRef,
    //     { opacity: 0 },
    //     { opacity: 1, visibility: "visible", duration: 0.5 },
    //     4
    //   )
    //   .fromTo(
    //     this.tapHereRef,
    //     { opacity: 0, scale: 0 },
    //     { opacity: 1, scale: 1, visibility: "visible", duration: 0.5 }
    //   )

    TweenLite.set(this.scene3MagRefs, {
      transformOrigin: "50% 50%",
    })
    TweenLite.set(this.zoomed.current, {
      scale: parent.zoomScale,
    })

    this.tl
      .set(frameRef, { opacity: 1, visibility: "visible" }, "scene2-ended")
      .from(
        frameRef,
        0.4,
        {
          x: "+=1000",
          ease: Power2.easeOut,
        },
        "scene2-ended+=0.9"
      )
      .addLabel("frame-ready", "+=1")
      .set(actualRef, { autoAlpha: 1, visibility: "visible" }, "frame-ready")
      .from(
        actualRef,
        0.4,
        {
          scaleY: 0,
          stagger: 0.1,
          ease: Elastic.easeOut.config(0.5, 0.2),
          // ease: Power2.easeOut,
        },
        "frame-ready"
      )
      .set(zoomRef, { opacity: 1, visibility: "visible" })
      .set(this.scene3MagRefs, {
        opacity: 0.5,
        visibility: "visible",
        scale: 0.5,
      })
      .add(followPathTl)
  }

  finishPlayback(parent) {
    this.viewport.current.addEventListener(
      "mouseleave",
      this.leaveAction.bind(parent)
    )
    this.viewport.current.addEventListener(
      "mousemove",
      this.moveMouse.bind(parent)
    )
    this.coverTl.play()
  }

  leaveAction(e) {
    this.magTl.reverse()
  }

  moveMouse(e) {
    // in order to move SVG object I will need local svg coordinated inside
    // viewport, but mouse event provides screen coordinates. Below,
    // Screen coordinates are converted into svg coordinates using inverse
    // transform matrix
    console.log(e)
    const svg = this.viewport.current
    const point = svg.createSVGPoint()
    point.x = e.x
    point.y = e.y
    const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse())
    this.moveMagSvg(svgPoint)
  }

  moveMagSvg(p) {
    // here I will re-calculate origin of the zoomed version and new location of the lens
    const zoomBBox = this.zoomed.current.getBBox()
    console.log(zoomBBox)
    const [x0, y0] = [zoomBBox.x, zoomBBox.y]

    this.magTl.play()
    this.timer.restart(true)
    TweenLite.set(this.scene3MagRefs, {
      x: p.x,
      y: p.y,
    })
    TweenLite.set(this.zoomed.current, {
      x: 0 + (p.x - x0) * (1 - this.zoomScale),
      y: 0 + (p.y - y0) * (1 - this.zoomScale),
    })
  }

  getRefsByType(refObj, refType) {
    return refObj.refs
      .filter((_, index) => refObj.type[index] === refType)
      .map(item => item.current)
  }

  getRefsById(refObj, pattern) {
    var rg = RegExp(pattern)
    return refObj.refs
      .filter((_, index) => rg.test(refObj.ids[index]))
      .map(item => item.current)
  }

  getRefById(refObj, pattern) {
    var rg = RegExp(pattern)
    return refObj.refs.find((_, index) => rg.test(refObj.ids[index])).current
  }

  renderScene(scene, sceneRefs) {
    sceneRefs.ids = scene.map(item => item.id)
    sceneRefs.type = scene.map(item => item.type)
    sceneRefs.refs = scene.map(item => React.createRef())
    return (
      <Fragment>
        {scene.map((obj, index) => {
          const CompType = obj.component
          return (
            <CompType key={index} ref={sceneRefs.refs[index]} {...obj.props} />
          )
        })}
      </Fragment>
    )
  }

  render() {
    return (
      <section className="default-section">
        <svg
          className={styles.viewport}
          viewBox="0 0 800 400"
          preserveAspectRatio="xMidYMid meet"
          ref={this.viewport}
        >
          {this.renderScene(Scene1, this.scene1Refs)}
          {this.renderScene(Scene2, this.scene2Refs)}
          {this.renderScene(Scene3Actual, this.scene3Refs)}
          <clipPath id="zoom-clip" ref={this.clippath} visibility="visible">
            <circle r="100" cx="0" cy="0" fill="#fff" />
          </clipPath>
          <Scene3Lens ref={this.lens} x="0" y="0" />
          <g clipPath="url(#zoom-clip)">
            <g id="zoomed" ref={this.zoomed}>
              {this.renderScene(Scene3Zoom, this.scene3ZoomRefs)}
            </g>
          </g>
        </svg>
      </section>
    )
  }
}
