import TableFrame from "./TableFrame"
import TapHere from "./TapHereText"
import TextCorrection from "./TextCorrection"
import styles from "../../../../css/home/Scene3.module.css"

import actuals from "../../../../constants/AnimatedHero/Scene2Actuals"
import correct from "../../../../constants/AnimatedHero/Scene2Correct"

const colWidths = [10, 160, 120, 120, 70, 70, 90, 60, 70]
const rowHights = [35, 20, 30, 26, 26, 26, 26, 26, 26, 26]
const alignRight = [false, false, false, true, true, true, true, true, true]

// cumulative sum solution from here https://stackoverflow.com/a/55261098
const colX = colWidths.map((sum => value => (sum += value))(0))
const rowY = rowHights.map((sum => value => (sum += value))(0))

function cellText(content, col, row) {
  if (content) {
    var st = {
      x: colX[col],
      y: rowY[row],
      textAnchor: alignRight[col] ? "end" : null,
    }
    if (typeof content === "string") {
      return {
        actual: content,
        ...st,
      }
    } else if (typeof content === "object") {
      return {
        actual: actuals[row][col],
        ...content,
        ...st,
        crossStyle: styles.crossLine,
      }
    }
  }
  return null
}

function makeRows(data) {
  return data
    .map((row, rowIndex) => {
      return (
        row
          .map((cell, colIndex) => {
            return cellText(cell, colIndex, rowIndex)
          })
          //removing all null elements
          .filter(n => n)
      )
    })
    .filter(n => n)
}

var actualRows = makeRows(actuals)
// combine first two rows as they are effectively the same 2-line header
// actualRows = [
//   Array.prototype.concat.apply([], actualRows.slice(0, 2)),
//   ...actualRows.slice(2),
// ]

const correctRows = Array.prototype.concat.apply([], makeRows(correct))

function exportRows(rows, idPrefix, type) {
  return rows.map((row, index) => {
    return {
      id: `${idPrefix}${index}`,
      type: type,
      component: TextCorrection,
      props: {
        x: 10,
        y: 60,
        style: index < 2 ? styles.tableHeader : styles.tableBody,
        cells: row,
      },
    }
  })
}

const frameGeometry = {
  x: 0,
  y: 50,
  height: 300,
  width: 800,
}

const frame = {
  id: "frame",
  type: "frame",
  component: TableFrame,
  props: {
    ...frameGeometry,
    id: "frame",
  },
}

const coverFrame = {
  id: "coverFrame",
  type: "cover",
  component: TableFrame,
  props: {
    ...frameGeometry,
    cover: true,
    id: "coverFrame",
  },
}

const tapHere = {
  id: "tapHere",
  type: "cover",
  component: TapHere,
  props: {
    ...frameGeometry,
    text: "Tap or hover mouse here ...",
  },
}

// const actualRowsExport = actualRows.map((row, index) => {
//   return {
//     id: `text${index}`,
//     type: "actual",
//     component: TextCorrection,
//     props: {
//       x: 10,
//       y: 60,
//       style: index < 2 ? styles.tableHeader : styles.tableBody,
//       cells: row,
//     },
//   }
// })

export const Scene3Actual = [
  frame,
  ...exportRows(actualRows, "text", "actual"),
  // {
  //   id: "zoom-path",
  //   type: "actual",
  //   component: LensPath,
  //   props: { x: 150, y: 150 },
  // },
  // coverFrame,
  // tapHere,
]

export const Scene3Zoom = [
  frame,
  ...exportRows(actualRows, "zoomtext", "zoom"),
  {
    id: "correct-text",
    type: "zoom",
    component: TextCorrection,
    props: {
      x: 10,
      y: 60,
      style: styles.correctValue,
      cells: correctRows,
    },
  },
]
