import React, { forwardRef } from "react"

const Lens = forwardRef((props, ref) => {
  const scale = props.scale ? props.scale : 1
  const x = props.x ? props.x : 0
  const y = props.y ? props.y : 0
  return (
    <g
      ref={ref}
      id="zoom"
      fill="var(--mainGray)"
      stroke="black"
      transform={`matrix(${scale},0,0,${scale},${x},${y})`}
      className="svg-comp"
    >
      <circle r="104" cx="0" cy="0" fill="none" stroke="red" />
    </g>
  )
})

export default Lens
