import React, { forwardRef } from "react"

const BI = forwardRef((props, ref) => {
  return (
    <g
      id="bi"
      transform-origin="5.29px 5.29px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      opacity="0"
    >
      <path
        d="M 12.815755,9.9218748 H 1.653646 V 2.0670565 c 0,-0.228414 -0.185002,-0.413411 -0.4134118,-0.413411 H 0.4134113 C 0.185002,1.6536455 0,1.8386425 0,2.0670565 v 8.6816415 c 0,0.456565 0.3702619,0.826823 0.8268228,0.826823 H 12.815755 c 0.22841,0 0.413412,-0.184997 0.413412,-0.413411 v -0.826823 c 0,-0.228415 -0.185002,-0.4134122 -0.413412,-0.4134122 z M 11.988934,2.4804685 H 8.938472 c -0.552421,0 -0.829148,0.667914 -0.438474,1.058598 l 0.837158,0.837155 -1.89575,1.896004 -1.895749,-1.895753 c -0.322979,-0.322977 -0.84646,-0.322977 -1.16918,0 l -1.774826,1.774825 c -0.161489,0.161502 -0.161489,0.423241 0,0.584729 l 0.58446,0.584452 c 0.161489,0.161488 0.423229,0.161488 0.584719,0 l 1.190108,-1.190361 1.89575,1.895753 c 0.322978,0.3229773 0.846459,0.3229773 1.169179,0 l 2.480467,-2.480469 0.837159,0.837155 c 0.390675,0.390671 1.058593,0.113903 1.058593,-0.438481 v -3.050196 c 2.92e-4,-0.228414 -0.184744,-0.413411 -0.413152,-0.413411 z"
        id="bi-path"
        fill="#5aa02c"
        strokeWidth="0.02583822"
      />
    </g>
  )
})

export default BI
