import React from "react"
import styles from "../../css/home/QA.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Question from "./Question"

const getQA = graphql`
  query getQA {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/qa//" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
          }
          body
        }
      }
    }
  }
`

const QA = () => {
  const data = useStaticQuery(getQA)
  const qas = data.allMdx.edges
  return (
    <section className={styles.qa}>
      <div className={styles.center}>
        {qas.map(({ node }, index) => {
          return <Question key={index} qdata={node} />
        })}
      </div>
    </section>
  )
}

export default QA
