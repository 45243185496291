import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styles from "../../css/home/Question.module.css"

const Question = ({ qdata }) => {
  const { title } = qdata.frontmatter
  const body = qdata.body
  return (
    <section className={styles.question}>
      <h6>{title}</h6>
      <MDXRenderer>{body}</MDXRenderer>
    </section>
  )
}

export default Question
