import React, { forwardRef } from "react"

const Wires = forwardRef((props, ref) => {
  return (
    <g
      id="wires"
      transform-origin="37.99px 28.765px"
      ref={ref}
      transform={`matrix(${props.scale},0,0,${props.scale},${props.x},${props.y})`}
      className="svg-comp"
      stroke="var(--darkGray)"
      strokeWidth="0.75179154"
      fill="none"
      opacity="0"
    >
      <path
        d="m 0.37593336,14.765828 v 2.19989 c 0,0.79547 0.70222904,1.43586 1.57450204,1.43586 10.3412076,0 20.6824456,0 31.0236746,0 0.872282,0 1.574511,0.64039 1.574511,1.43586 v 2.19987"
        id="path-to-agent"
      />
      <path
        d="m 76.353227,14.765828 v 2.19989 c 0,0.79547 -0.70222,1.43586 -1.57451,1.43586 -10.34123,0 -20.68245,0 -31.023679,0 -0.872282,0 -1.574511,0.64039 -1.574511,1.43586 v 2.19987"
        id="path-to-bi"
      />
      <path d="M 38.364589,0.38124777 V 22.091848" id="path-to-handshake" />
      <path
        d="m 76.353237,43.419538 v -2.19988 c 0,-0.79546 -0.70223,-1.43586 -1.57452,-1.43586 -10.34122,0 -20.68245,0 -31.023679,0 -0.872273,0 -1.574511,-0.6404 -1.574511,-1.43586 v -2.19989"
        id="path-to-bank"
      />
      <path
        d="m 0.37593336,43.463698 v -2.19987 c 0,-0.79547 0.70222904,-1.43586 1.57451204,-1.43586 10.3412076,0 20.6824356,0 31.0236646,0 0.872282,0 1.574511,-0.64041 1.574511,-1.43587 v -2.19989"
        id="path-to-factory"
      />
      <path d="M 38.364589,57.910598 V 36.199988" id="path-to-cart" />
    </g>
  )
})

export default Wires
